import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * Component: Video
 */
export function Video() {
  console.log("Video mounted.");

  document.addEventListener("DOMContentLoaded", function() {
    // Get elements
    const videoTrigger = document.getElementById('videoTrigger');
    const videoModal = document.getElementById('videoModal');
    const closeModal = document.getElementById('closeModal');
    const modalVideo = document.getElementById('modalVideo');

    // Show modal on click
    if (videoTrigger) {
        videoTrigger.addEventListener('click', () => {
            videoModal.style.display = 'flex';
            if (modalVideo) {
                modalVideo.play();
            }
        });
    }

    // Close modal on click of close button
    if (closeModal) {
        closeModal.addEventListener('click', () => {
            videoModal.style.display = 'none';
            if (modalVideo) {
                modalVideo.pause();
                modalVideo.currentTime = 0;
            }
        });
    }

    // Close modal when clicking outside the video
    window.addEventListener('click', (event) => {
        if (event.target === videoModal) {
            videoModal.style.display = 'none';
            if (modalVideo) {
                modalVideo.pause();
                modalVideo.currentTime = 0;
            }
        }
    });
});

}
